import React from "react";
import { TweenMax, Power2 } from "gsap";
import * as styles from "./index.module.scss";
import { ScrollToPlugin } from "gsap/all";

const plugins = [ScrollToPlugin]; // eslint-disable-line no-unused-vars

export default function NextSectionBtn({
  scrollTo,
  type,
  children,
  method,
  textColor,
  textStyle
}) {
  const handleClick = () => {
    if (method) {
      method();
    }
    if (scrollTo && typeof window !== "undefined") {
      TweenMax.to(window, 1, {
        scrollTo: scrollTo,
        ease: Power2.easeInOut
      });
    }
  };
  const fontColor = textColor === "white" ? styles.white : "";
  const fontStyle = textStyle === "thin" ? styles.thin : "";
  const direction = type === "up" ? styles.up : "";
  return (
    <button
      className={`${styles.nextSectionBtn} ${direction} relative`}
      onClick={handleClick}
    >
      <div className={styles.chevron}></div>
      <div className={styles.chevron}></div>
      <div className={styles.chevron}></div>
      {children && (
        <span className={`${styles.btnText} ${fontColor} ${fontStyle}`}>
          {children}
        </span>
      )}
    </button>
  );
}
