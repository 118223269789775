/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { TweenMax, Power2 } from "gsap";
import * as styles from "./index.module.scss";
import loadStates from "../../../hocs/loadStates";

class Indicator extends Component {
  constructor(props) {
    super(props);

    // Ref
    this.dots = null;
  }
  componentDidMount() {
    TweenMax.to(this.dots, 1, { autoAlpha: 1, ease: Power2.easeOut });
  }

  render() {
    const { slideIndex, goToSlide, numOfSlides } = this.props;
    let slidesArray = [];
    let i;
    for (i = 0; i < numOfSlides; i++) {
      slidesArray.push(i);
    }
    return (
      <ul
        className={`${styles.indicator} fixed flex flex-col z-10`}
        ref={dots => (this.dots = dots)}
      >
        {slidesArray.map((slide, i) => (
          <Dot
            key={i}
            active={slideIndex === i}
            goToSlide={goToSlide}
            slideNum={i + 1}
          />
        ))}
      </ul>
    );
  }
}

function Dot({ active, goToSlide, slideNum }) {
  const isActive = active ? styles.active : "";
  const handleClick = () => {
    goToSlide(slideNum);
  };
  return (
    <li
      className={`${styles.dot} ${isActive} w-full cursor-pointer`}
      onClick={handleClick}
    >
      <svg viewBox="0 0 10 10">
        <circle cx="5" cy="5" r="4"></circle>
      </svg>
    </li>
  );
}

export default loadStates(Indicator);
