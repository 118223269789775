// extracted by mini-css-extract-plugin
export var animate = "index-module--animate--y-gfE";
export var btnText = "index-module--btnText--cTN3c";
export var chevron = "index-module--chevron--SY38W";
export var cls1 = "index-module--cls1--8jT7q";
export var cls2 = "index-module--cls2--6aqV9";
export var cls3 = "index-module--cls3--j1fc8";
export var cls4 = "index-module--cls4--UM3kX";
export var move = "index-module--move--ilHTQ";
export var nextSectionBtn = "index-module--nextSectionBtn--dQ+0t";
export var pulse = "index-module--pulse--4Tnjy";
export var up = "index-module--up--n0xX+";
export var white = "index-module--white--P9fBz";