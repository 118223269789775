import React from "react";
import * as styles from "./index.module.scss";

function ContactButton({ icon, mobielCenter }) {
  const iconType = icon === "flat" ? styles.flat : "";
  return (
    <button
      className={`${styles.button} w-full bg-brand-yellow text-left cursor-pointer py-3 px-8 flex justify-between`}
    >
      <span className="uppercase tracking-wide title title-xs self-center font-bold">
        Say Hello
      </span>
      <div className={`${styles.icon} ${iconType} self-center`}>
        {icon === "flat" ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M21 12l-18 12v-24z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            viewBox="0 0 24 24"
          >
            <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
          </svg>
        )}
      </div>
    </button>
  );
}

ContactButton.defaultProps = {
  icon: "normal"
};

export default ContactButton;
